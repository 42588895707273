import { Headline } from '@/components/Headline';
import { Title } from '@/components/Typography';
import { AVAILABLE_THEMES, AVAILABLE_THEMES_TYPE } from '@/styles/constants';

export const TitleElement = ({
  text,
  themeName,
  tag,
  dataTest,
}: {
  text: string;
  themeName?: AVAILABLE_THEMES_TYPE;
  tag?: string;
  dataTest?: string;
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <Headline as="h3" type="l" className="font-primary mb-4" data-test={dataTest}>
        {text}
      </Headline>
    );
  }

  return (
    <Title
      tag={tag || 'div'}
      type={themeName ? 'xl' : 'md'}
      data-test={dataTest}
      className={
        themeName === AVAILABLE_THEMES.christmas_blue_edition ||
        themeName === AVAILABLE_THEMES.christmas
          ? 'text-white'
          : ''
      }
    >
      {text}
    </Title>
  );
};
