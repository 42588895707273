import { ParagraphText } from '@/components/ParagraphText';
import { Text as Paragraph } from '@/components/Typography';
import { AVAILABLE_THEMES, AVAILABLE_THEMES_TYPE } from '@/styles/constants';

export const DescElement = ({
  text,
  themeName,
  dataTest,
}: {
  text: string;
  themeName?: AVAILABLE_THEMES_TYPE;
  tag?: string;
  dataTest?: string;
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <ParagraphText type="m" data-test={dataTest}>
        {text}
      </ParagraphText>
    );
  }

  return (
    <Paragraph
      tag="p"
      type="lg"
      data-test={dataTest}
      className={
        themeName === AVAILABLE_THEMES.christmas_blue_edition ||
        themeName === AVAILABLE_THEMES.christmas
          ? 'text-tertiary'
          : ''
      }
    >
      {text}
    </Paragraph>
  );
};
